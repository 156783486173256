import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import styles from "./Footer.module.css"; // Import your module.css file
import organic from "../../edimages/organic-badge-freeimg.png";
import logo1 from "../../edimages/logo1.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.text}>
          <div className={styles.logo}>
            <h1 style={{marginBottom:'50px'}}>Airavat Oils</h1>
            {/* <img src={logo1} alt="organic"/> */}
            <img
              src={organic}
              alt="organic"
              style={{ width: "23%", marginTop: "-34px" }}
            />
          </div>
          <p>
            Airavat Brand is the number one mustard oil distributor to trust.
            Established in 2022, Airavat Brand produces only the highest
            quality, 100% pure and organic mustard oil.
          </p>
        </div>
        <div className={styles.icons}>
          <div className={styles.icon}>
            <a
              href="https://maps.app.goo.gl/tkL3z5Fm4W5VHuEq5"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                style={{ color: "aqua" }}
                className={styles.fonticon}
              />
              <span>
                Near By Ram Maruti Work Shop, AB Road, Morena, 476023 - M.P.
              </span>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="mailto:MPEDOIL2016@GMAIL.COM" className={styles.link}>
              <FontAwesomeIcon icon={faEnvelope} style={{ color: "aqua" }}  className={styles.fonticon} />
              <span>MPEDOIL2016@GMAIL.COM</span>
            </a>
          </div>
          <div className={styles.icon}>
            <div>
              <a href="tel:+919425126139" className={styles.link}>
                <FontAwesomeIcon icon={faPhone} style={{ color: "aqua" }}  className={styles.fonticon} />
                <span>+91 9425126139</span>
              </a>
            </div>
            <div>
              <a href="tel:+919425126024" className={styles.link}>
                <FontAwesomeIcon icon={faPhone} style={{ color: "aqua" }}  className={styles.fonticon} />
                <span>+91 9425126024</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.quickLinks}>
          <h2>Quick Links</h2>
          <ul>
            <li>
              <Link to="/" className={styles.quickLink}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className={styles.quickLink}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/products" className={styles.quickLink}>
                Our Products
              </Link>
            </li>
            <li>
              <Link to="/contact" className={styles.quickLink}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.socialIcons}>
          <FontAwesomeIcon icon={faFacebook} className={styles.fonticon}/>
          <FontAwesomeIcon icon={faTwitter} className={styles.fonticon}/>
          <FontAwesomeIcon icon={faInstagram} className={styles.fonticon}/>
        </div>
        <div className={styles.copyright}>
          <p>
            Copyright © 2024 | Airavat Oils 
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
