import React, { useState, useRef, useEffect } from "react";
import styles from "./CustomSlider.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const CustomSlider = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const thumbnailContainerRef = useRef(null);

  useEffect(() => {
    centerSelectedImage();
  }, [currentImage]);

  const handleImageSelect = (index) => {
    setCurrentImage(index);
  };

  const previousSlide = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? images.length - 1 : prevImage - 1
    );
  };

  const nextSlide = () => {
    setCurrentImage((prevImage) =>
      prevImage === images.length - 1 ? 0 : prevImage + 1
    );
  };

  const centerSelectedImage = () => {
    const thumbnailContainer = thumbnailContainerRef.current;
    if (thumbnailContainer) {
      const thumbnailWidth = thumbnailContainer.children[0].offsetWidth;
      const containerWidth = thumbnailContainer.offsetWidth;
      const scrollOffset =
        thumbnailWidth * currentImage - containerWidth / 2 + thumbnailWidth / 2;
      thumbnailContainer.scrollTo({
        left: scrollOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className={styles.customSlider}>
      <div className={styles.sliderDisplay}>
        {/* <a
          className={`${styles.sliderControl} ${styles.prev}`}
          onClick={() => {
            previousSlide();
            centerSelectedImage();
          }}
        >
          <i className="fa-solid fa-angle-left"></i>
        </a> */}
        <img
          src={images[currentImage]}
          alt={`Product ${currentImage + 1}`}
          className={styles.displayedImage}
        />
        {/* <a
          className={`${styles.sliderControl} ${styles.next}`}
          onClick={() => {
            nextSlide();
            centerSelectedImage();
          }}
        >
          <i className="fa-solid fa-angle-right"></i>
        </a> */}
      </div>
      <div className={styles.sliderContainer}>
        <div className={styles.leftdownslidericon}>
          <a
            className={`${styles.sliderControldown} ${styles.leftslide}`}
            onClick={() => {
              previousSlide();
              centerSelectedImage();
            }}
          >
             <FontAwesomeIcon icon={faAngleLeft} />
          </a>
        </div>

        <div className={styles.thumbnailContainer} ref={thumbnailContainerRef}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`${styles.thumbnailItem} ${
                index === currentImage ? styles.active : ""
              }`}
              onClick={() => handleImageSelect(index)}
            >
              <img
                src={image}
                alt={`Product ${index + 1}`}
                className={
                  index === currentImage
                    ? styles.selectedThumbnail
                    : styles.thumbnail
                }
              />
            </div>
          ))}
        </div>

        <div className={styles.rightdownslidericon}>
          <a
            className={`${styles.sliderControldown} ${styles.rightslide}`}
            onClick={() => {
              nextSlide();
              centerSelectedImage();
            }}
          >
             <FontAwesomeIcon icon={faAngleRight} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CustomSlider;
