// VideoPlayer.js

import React from 'react';
import styles from './VideoPlayer.module.css'; // Import CSS module

const VideoPlayer = ({ src }) => {
    return (
        <div className={styles.videoContainer}>
              <div className={styles.btntest}>
                <p className={styles.text}>
                    To visit our lab page is to embark on a journey of exploration and innovation. Dive into a world where ideas take shape, experiments lead to discoveries, and creativity knows no bounds.</p>
            </div>
            <div className={styles.overlay}></div>
            <video autoPlay loop muted className={styles.video}>
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
          
            {/* <div className={styles.btntest}>
                <button className={styles.multicolorButton}>Visit Labority</button>
            </div> */}

        </div>
    );
};

export default VideoPlayer;