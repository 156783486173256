import React from "react";
import leaf from "../../../edimages/basil-leaf.png";
import groupoil from "../../../edimages/homeimage9.png";
import owner1 from "../../../edimages/mrajay.png";
import owner2 from "../../../edimages/manoj.png";
import profile1 from "../../../edimages/profile.png";
import { Link } from "react-router-dom";
import classes from "./Aboutus.module.css";
import SubscriberCard from "./SubscriberCard";
import ReviewCard from "../../miniComponents/ReviewCard";
import certificate from "../../../edimages/organic-badge-freeimg.png";
import logoleaf from "../../../edimages/logo-leaf-new.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import {useRef } from "react";

const AboutUs = () => {
  const footerRef = useRef(null);


  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: "auto" });
  };

  return (
    <div className={classes.aboutus}>
      <div>
        <img src={leaf} alt="leaf" />
      </div>
      <div className={classes.aboutcontent}>
        <div>
          <h1 className={classes.headtext}>We Are Your Favourite Store.</h1>
          <p className={classes.aboutdesc}>
            AIRAVAT Oils is the number one mustard oil distributor to trust.
            Established in 2022, Airavat Brand produces only the highest quality,
            100% pure and organic mustard oil. Our oil comes from the finest
            organic mustard seeds, grown sustainably without the use of
            pesticides or chemicals.
            <br/>
            <br/>
            <br/> The seeds are slowly cold-pressed to extract every drop of
            natural flavor and nutrition, resulting in an oil that is rich in
            antioxidants and healthy fats. So don’t wait any longer, check out
            our products page today and get to know AIRAVAT Brand!
          </p>
        </div>
        <div className={classes.productimage}>
          <img src={groupoil} alt=" product" />
        </div>
      </div>

      <div className={classes.aboutcontent}>
        <div>
          <div className={classes.ownersec}>
            <img src={owner1} alt="owner1" />
          </div>
          <div className={classes.ownerdetails}>
            <h1>Mr. Manoj Agrawal  - Managing Director</h1>
            <p>
              The Managing Director role at AIRAVAT Oils is not for the faint of
              heart. As the head honcho, you’ll be steering the strategic
              direction of a company whose oils and lubricants keep the engines
              of progress running across industries. AIRAVAT Oils wants to fuel
              your leadership fire.
            </p>
          </div>
          <div className={classes.btn}>
            <Link to="#" className={classes["product-link"]} onClick={scrollToFooter}>
              Contact Us
            </Link>
          </div>
        </div>

        <div>
          <div className={classes.ownersec}>
            <img src={owner2} alt="owner2" />
          </div>
          <div className={classes.ownerdetails}>
            <h1>Mr. Ajay Bansal - Executive Director</h1>
            <p>
              The Executive Director will oversee day-to-day operations, build
              and lead high-performing teams, and work closely with the board to
              determine and execute key organizational priorities.This focused
              blend helps busy executives stay calm and energized for maximum
              productivity.
            </p>
          </div>
          <div className={classes.btn}>
            <Link to="#" className={classes["product-link"]} onClick={scrollToFooter}>
            Contact Us
            </Link>
          </div>
        </div>
      </div>

      <div className={classes.subscard}>
     <SubscriberCard />
      </div>
      <div className={classes.flexdesign}>
        <div className={classes.reviewCard}>
          <ReviewCard
            profileimg={profile1}
            desc="100% pure best quality oil at reasonable price! No adulteration. Strongly recommended to anyone who doesn't want to compromise with health!!"
            name="Vikram Singh"
          />
        </div>
        <div className={classes.listitem}>
          <div className={classes.certificate}>
            <div className={classes.certimage}>
              <img src={certificate} alt="certificate" />
              <h1>Certified Products</h1>
            </div>
            <div className={classes.content}>
              <p>
                Airavat Brand produces only the highest quality, 100% pure and
                organic mustard oil.
              </p>
            </div>
          </div>
          <div>
            <h1>We Deal With Various Quality Organic Products!</h1>
            <img src={logoleaf} alt="leaf" />
          </div>

          <div className={classes.gridproduct}>
            <div className={classes.griddivide}>
              <ul className={classes.ulstyle}>
                <li className={classes.listyle}>
                  <span>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                    />
                  </span>
                  <span className={classes.textstyle}>
                    15 Ltr Kacchi Ghani Mustard Oil Bucket
                  </span>
                </li>
                <li className={classes.listyle}>
                  <span>
                  <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                    />
                  </span>
                  <span className={classes.textstyle}>
                   15 Kg Kacchi Ghani Mustard Oil Tin
                  </span>
                </li>
                <li className={classes.listyle}>
                  <span>
                  <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                    />
                  </span>
                  <span className={classes.textstyle}>
                    5 Ltr Kacchi Ghani Mustard Oil Jar
                  </span>
                </li>
                <li className={classes.listyle}>
                  <span>
                  <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                    />
                  </span>
                  <span className={classes.textstyle}>
                    1 Ltr Kacchi Ghani Mustard Oil Bottle
                  </span>
                </li>
              </ul>
            </div>

            <div className={classes.griddivide}>
              <ul className={classes.ulstyle}>
                <li className={classes.listyle}>
                  <span>
                  <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                    />
                  </span>
                  <span className={classes.textstyle}>
                    1 Ltr Kacchi Ghani Mustard Oil Pouch
                  </span>
                </li>
                <li className={classes.listyle}>
                  <span>
                  <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                    />
                  </span>
                  <span className={classes.textstyle}>
                    500 ML Kacchi Ghani Mustard Oil Bottle
                  </span>
                </li>
                <li className={classes.listyle}>
                  <span>
                  <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "green" }}
                    />
                  </span>
                  <span className={classes.textstyle}>
                    200 ML Kacchi Ghani Mustard Oil Bottle
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:'30px'}} ref={footerRef}>
      </div>
    </div>
  );
};

export default AboutUs;
