// Your React component file

import React from "react";
import classes from "./contactcard.module.css"; // Import your CSS module file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import leaf from "../../edimages/basil-leaf.png";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
const Contactcard = () => {
  return (
    <>
    <div className={classes.logo}>
      <img src={leaf} alt="logo" />
    </div>
    <div className={classes.container1}></div>
      <div className={classes.container}>
        {/* Three cards in a row */}
        <div className={classes.card}>
          <div className={classes.textcenter}>
            <FontAwesomeIcon
              icon={faPhone}
              style={{ color: "green", fontSize: "30px", marginBottom: "10px" }}
            />
              <a href="tel:+919425126139" className={classes.link}>
                <span>+91 9425126139</span>
              </a>
              <a href="tel:+919425126139" className={classes.link}>
                <span>+91 9425126024</span>
              </a>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.textcenter}>
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ color: "green", fontSize: "30px", marginBottom: "10px" }}
            />
            <a href="mailto:MPEDOIL2016@GMAIL.COM" className={classes.link}>
              <span>MPEDOIL2016@GMAIL.COM</span>
            </a>
          </div>
        </div>
        <div className={classes.card}>
          <div className={classes.textcenter}>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ color: "green", fontSize: "30px", marginBottom: "10px" }}
            />
               <a
              href="https://maps.app.goo.gl/tkL3z5Fm4W5VHuEq5"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}>
              <span>
                Near By Ram Maruti Work Shop, AB Road, Morena, 476023 - M.P.
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactcard;
