import React, { useState, useEffect } from 'react';
import styles from './Slideshow.module.css'; // Import module CSS file for styling
import banner1 from '../../../edimages/homeimage1.png'; 
import banner2 from '../../../edimages/homeimage2.png'; 
import banner3 from '../../../edimages/homeimage3.png'; 
import banner4 from '../../../edimages/homeimage4.png'; 
const SlideShow = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [banner1,banner2,banner3,banner4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); 

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className={styles.slideshow}>
      <img src={images[currentImage]} alt={`Slide ${currentImage}`} className={styles.slideImage} />
    </div>
  );
};

export default SlideShow;
