import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { Contact } from "./components/Pages/Contact";
import AllProducts from "./components/Pages/AllProducts";
import ProductDetails from './components/miniComponents/Productsdetails/ProductDetails';
import NewBar from "./components/miniComponents/navbar/NewBar";
import AboutUs from "./components/Pages/aboutus/AboutUs";
import NotFound from "./NotFound";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/miniComponents/Footer";

function App() {
  return (
    <>
      <Router>
          <ScrollToTop/>
        <NewBar />
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<AllProducts />} />
            <Route path="/product_details/:id" element={<ProductDetails />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact/>} />
            <Route element={<NotFound/>} />
            <Route path="/*" element={<Navigate to="/"/>}/>
          </Routes>
        </div>
        <div style={{marginTop:'30px'}}>
        <Footer/>
      </div>
      </Router>
    </>
  );
}

export default App;
