import React from "react";
import Contactcard from "./Contactcard";
import classes from "./contactcard.module.css";
import { useEffect } from "react";
export const Contact = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, []);
  return (
    <div>
      <h1 className={classes.contcenter}>Get In Touch</h1>
      <Contactcard />
      <iframe
      title="location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3571.3709569683733!2d77.99326911503573!3d26.47599838331763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3976b3cebc2e556f%3A0xa32ca83bdab61d4c!2zTXAgZWRpYmxlIG9pbHMgbWlsbCBQdnQo4KSP4KSuIOCkquClgCDgpI_gpKHgpL_gpKzgpLIg4KSR4KSv4KSyIOCkruClgOCksiDgpKrgpY3gpLDgpL7gpIfgpLXgpYfgpJ8p!5e0!3m2!1sen!2sin!4v1688724931051!5m2!1sen!2sin"
        width="100%"
        height="450"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};
