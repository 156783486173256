import React, { useState, useEffect } from "react";
import styles from "./SubscriberCard.module.css";

const SubscriberCard = () => {
  const [productsCount, setProductsCount] = useState(1);
  const [satisfactionCount, setSatisfactionCount] = useState(1);
  const [shouldStartCounters, setShouldStartCounters] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1400) {
        setShouldStartCounters(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (shouldStartCounters) {
      const productsTimer = setInterval(() => {
        if (productsCount < 100) {
          if (productsCount === 99) {
            clearInterval(productsTimer);
            setTimeout(() => {
              setProductsCount(100);
            }, 2000);
          } else {
            setProductsCount((prevCount) => prevCount + 1);
          }
        }
      }, 10);

      const satisfactionTimer = setInterval(() => {
        if (satisfactionCount < 90) {
          if (satisfactionCount === 89) {
            clearInterval(satisfactionTimer);
            setTimeout(() => {
              setSatisfactionCount(90);
            }, 2000);
          } else {
            setSatisfactionCount((prevCount) => prevCount + 1);
          }
        }
      }, 10);

      return () => {
        clearInterval(productsTimer);
        clearInterval(satisfactionTimer);
      };
    }
  }, [productsCount, satisfactionCount, shouldStartCounters]);
console.log(window.scrollY)
  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h2 className={styles.cardTitle}>Numbers Speak For Themselves!</h2>
        <div className={styles.contents}>
          <div className={styles.counter}>
            <span>{productsCount}+</span>
            <h2 className={styles.cardText}>Our Products</h2>
          </div>
          <div className={styles.counter}>
            <span>1+</span>
            <h2 className={styles.cardText}>Experienced</h2>
          </div>
          <div className={styles.counter}>
            <span>{satisfactionCount}+</span>
            <h2 className={styles.cardText}>Customer Satisfaction</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriberCard;
