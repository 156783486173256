import React from "react";
import styles from "./AllProductCard.module.css"; // Import the CSS Module
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const AllProductCard = ({id,thumbimage, tag}) => {
  const star= <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />;

  return (
    <Link to={`/product_details/${id}`} className={styles.cards} style={{ textDecoration: "none"}}>
      {/* Image */}
      <div className={styles.thumbnail}>
        <img src={thumbimage} alt="Example" className={styles.imgthumbnail}/>
      </div>

      {/* Title */}
      <div className={styles.title}>
        <p className={styles["card-title"]}>{tag}</p>
      </div>

      {/* Description */}
      <div className={styles.description}>
          <p>
            {star}
            {star}
            {star}
            {star}
            {star}
          </p>
      </div>
    </Link>
  );
};

export default AllProductCard;
