import React, { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { productsData } from "../collapse/ProductsData";
import styles from "./ProductDetails.module.css";
import ProductGallery from "../collapse/ProductGallery";
import Collapse from "../collapse/Collapse";
import certi from '../../../edimages/organic-badge-freeimg.png'
import images from "../../miniComponents/ExportImage";
import Card from "../../miniComponents/Card";
import leaf from "../../../edimages/logo-leaf-new.png";
const ProductDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("description");



  const product = productsData.find((product) => product.id === parseInt(id));

  if (!product) {
    return <div>Product not found</div>;
  }

  const { image, heading, description, subimage, subheading, detailsDesc } =
    product;

  const goBack = () => {
    navigate("/products");
  };

  // const handleTabChange = (tabName) => {
  //   setSelectedTab(tabName);
  // };
  const subheadingArray = subheading.split("\n");
  const detailsDescArray = detailsDesc.split("\n");

  return (
    <div className={styles["product-details-container"]}>
      <div className={styles["fixed-section"]}>
        {/* <span onClick={goBack} style={{ cursor: 'pointer' }}>
          Go Back
        </span> */}
        <div className={styles.imgdetails}>
          <ProductGallery
            heading={heading}
            subimages={subimage}
            subheadingArray={subheadingArray}
          />
          <span>
            <h2 className={styles.heading}>{heading}</h2>
            <p className={styles["product-description"]}>
              {subheadingArray.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
            <hr className={styles.hrline} />
            <div>
              <p>
                Category:
                <Link
                  to="/products"
                  style={{ color: "green", textDecoration: "none" }}
                >
                  <span style={{marginLeft:'10px'}}>Our Products</span> 
                </Link>
              </p>
              {/* <p>
                Tags:
                <Link
                  to="/products"
                  style={{ color: "green", textDecoration: "none" }}
                >
                  <span style={{marginLeft:'10px'}}> 1 Ltr  Kachi Ghani Mustard Oil, 15 Litre Kachi Ghani Mustard Oil
                </span> 
                </Link>
              </p> */}
            </div>
          </span>
        </div>
        <hr className={styles.hrline} />
        <div className={styles["product-links"]}>
          <span
            // onClick={() => handleTabChange('description')}
            className={
              selectedTab === "description"
                ? styles["active-tab"]
                : styles["product-link"]
            }
          >
            Description
          </span>
          {/* <span
            onClick={() => handleTabChange('review')}
            className={selectedTab === 'review' ? styles['active-tab'] : styles['product-link']}
          >
            Review
          </span> */}
        </div>
      </div>
      <div className={styles["scrollable-section"]}>
        {selectedTab === "description" && (
          <div>
            <p className={styles["product-detailsdescription"]} style={{marginBottom:'0px'}}>Welcome To <b>AIRAVAT OILS</b>
            <img src={certi} alt="cert" style={{ width: '6%',marginLeft: '10px'}}/>
            </p>
            <p className={styles["product-detailsdescription"]}>
              {detailsDescArray.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </div>
        )}
        {selectedTab === "review" && (
          <div>
            <p className={styles["product-specification"]}>review:</p>
          </div>
        )}
      </div>

      <div className={styles.customer}>
        <h1>Trending Products</h1>
        <img src={leaf} alt="logo" />
      </div>
      <div className={styles.Productcard}>
        {images.map((list, index) => (
          <Card key={index} thumbimage={list.image} tag={list.tag} id={list.id}/>
        ))}
      </div>

      <div style={{ marginTop: "50px" }}>
        <h3
          style={{
            fontWeight: "bolder",
            textAlign: "center",
            marginLeft: "10px",
          }}
        >
          Frequently Asked Questions: <span style={{color:"red"}}>(FAQ)</span>
        </h3>
        <Collapse />
      </div>
    </div>
  );
};

export default ProductDetails;
