import React from "react";
import styles from "./Card.module.css"; // Import the CSS Module
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Card = ({thumbimage, tag, cardheight, imgheight, id, btn }) => {
  const star= <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />;
  return (
    <Link to={`/product_details/${id}`} className={styles.cards} style={{ textDecoration: "none" ,height:cardheight}}>
      {/* Image */}
      
      <div className={styles.thumbnail}>
        <img src={thumbimage} alt="Example" className={styles.imgthumbnail} style={{height:imgheight}}/>
      </div>

      {/* Title */}
      <div className={styles.title}>
        <p className={styles["card-title"]}>{tag}</p>
      </div>

      {/* Description */}
      <div className={styles.description}>
          <p>
            {star}
            {star}
            {star}
            {star}
            {star}
          </p>
      </div>
    </Link>
  );
};

export default Card;
