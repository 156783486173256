import React, { useState } from 'react';
import styles from './collapse.module.css'; // Import CSS Module for styling
import { faqPageData } from '../collapse/ProductsData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
const Collapse = () => {
  const [activeItem, setActiveItem] = useState(null);

  const toggleItem = index => {
    setActiveItem(activeItem === index ? null : index);
  };

  return (
    <div className={styles['faq-container']}>
      {faqPageData.map((item, index) => (
        <div key={index} className={styles['faq-item']}>
          <div className={styles['question']} onClick={() => toggleItem(index)}>
            <span> {activeItem === index ? <FontAwesomeIcon icon={faMinus}  style={{ color: 'red' }} /> : <FontAwesomeIcon icon={faPlus} />}</span>
            <span className={styles.qabold}>{item.question}</span>
          </div>
          <div className={activeItem === index ? styles['answer-show'] : styles['answer']}>
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Collapse;
