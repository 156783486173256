import React from 'react'
import images from "../miniComponents/ExportImage";
import styles from './AllProducts.module.css';
import AllProductCard from './AllProductCard';
const AllProducts = () => {
  return (
    <div>
        <div className={styles.Productcard}>
        {images.map((list, index) => (
          <AllProductCard key={index} id={list.id} thumbimage={list.image} tag={list.tag}/>
        ))}
      </div>
    </div>
  )
}

export default AllProducts