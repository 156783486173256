import { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Navbar.module.css"; // Import CSS module
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from "../../../edimages/logo.png";

const NewBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };


  return (
    <nav className={styles.navbar}>
      <div className={styles.headercont}>
        <div className={styles.logo}>
          <NavLink to="/">
            <img
              src={logo}
              alt="logo"
              style={{ width: "55px", verticalAlign: "unset" }}
            />
          </NavLink>
        </div>
        <div className={styles.mobileMenu} onClick={toggleMenu}>
          <span className={styles.hamburger}>
            {!showMenu&&<FontAwesomeIcon icon={faBars} />}
            {showMenu&&<FontAwesomeIcon icon={faTimes} style={{fontSize:'30px', color:'#742d2d'}} />}
          </span>
        </div>
      </div>
      <div className={styles.linkflex}>
        <ul className={`${styles.navLinks} ${showMenu ? styles.show : ""}`}>
          <li>
            <NavLink
              to="/"
              className={styles["nav-links"]}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "underline",
                      fontWeight: "bolder",
                      color: "#000000",
                    }
                  : {}
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/products"
              className={styles["nav-links"]}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "underline",
                      fontWeight: "bolder",
                      color: "#000000",
                    }
                  : {}
              }
            >
              Our Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={styles["nav-links"]}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "underline",
                      fontWeight: "bolder",
                      color: "#000000",
                    }
                  : {}
              }
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={styles["nav-links"]}
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "underline",
                      fontWeight: "bolder",
                      color: "#000000",
                    }
                  : {}
              }
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NewBar;
