// images.js

import homeimage2 from "../../edimages/homeimage2.png";
import homeimage3 from "../../edimages/homeimage3.png";
import homeimage4 from "../../edimages/homeimage4.png";
import homeimage5 from "../../edimages/homeimage5.png";
import homeimage6 from "../../edimages/homeimage6.png";
import homeimage7 from "../../edimages/homeimage7.png";
import homeimage8 from "../../edimages/homeimage8.png";


const images = [
  {
    id:1,
    image: homeimage2,
    tag:'1 Ltr Kacchi Ghani Mustard Oil',
  },
  {
    id:2,
    image: homeimage3,
    tag:'1 Ltr Kacchi Ghani Mustard Oil',
  },
  {
    id:3,
    image: homeimage4,
    tag:'15 Ltr Bucket Kacchi Ghani Mustard Oil',
  },
  {
    id:4,
    image: homeimage5,
    tag:'15 Kg Kacchi Ghani Mustard Oil Tin',
  },
  {
    id:5,
    image: homeimage6,
    tag:'200 Ml Kacchi Ghani Mustard Oil',
  },
  {
    id:6,
    image: homeimage7,
    tag:'5 Ltr Jar Kacchi Ghani Mustard Oil',
  },
  {
    id:7,
    image: homeimage8,
    tag:'500 Ml Kacchi Ghani Mustard Oil',
  },

];

export default images;
