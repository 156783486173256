import React from "react";
import styles from "./ReviewCard.module.css"; // Import module CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';

const GreatDealCard = ({ profileimg, desc, name }) => {
  const star = <FontAwesomeIcon icon={faStar} style={{ color: "gold" }} />;
  return (
    <div className={styles.greatdealcard}>
      <div className={styles.cardContent}>
        <h1 className={styles.cardText1}>
          To Get The Deal Of The Day - Deal Today 
        </h1>
        <div className={styles.profile1}>
  
          <p>Shop Today With Us, Click
          "Shop Now" And Buy Your Oil Today </p>
        
        </div>
        <div style={{textAlign:'center',margin:'80px 0px 0px'}}>
        <Button variant="success" className={styles.btnntv}>SHOP NOW</Button>
        </div>
      </div>
    </div>
  );
};

export default GreatDealCard;
