import React from "react";
import classes from "./Products.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import logo1 from "../../edimages/logo1.png";
const Products = ({ id, image, tag, heading, description }) => {
  
  const star= <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />;
  return (
    <div className={classes["product-container"]}>
       <div className={classes.imageBackground1}>
        <img src={image} alt="Product" className={classes["product-image"]} />
      </div>
      <div>
        <div className={classes.tag}>
          <h5 className={classes.tvtaghead}>{tag}</h5>
          <h1 className={classes.tvtaghead2}>{heading}</h1>
          {/* <img src={logo1} alt="logo"/> */}
          <p>
            {star}
            {star}
            {star}
            {star}
            {star}
          </p>
        </div>
        <div className={classes["product-description"]}>
          <p>{description}</p>
        </div>
        <div className={classes.btn}>
          <Link
            to={`/products`}
            className={classes["product-link"]}
          >
            View Products
          </Link>
        </div>
      </div>
      <div className={classes.imageBackground2}>
        <img src={image} alt="Product" className={classes["product-image"]} />
      </div>
    </div>
  );
};

export default Products;
