import React, { useEffect, useState, useRef } from "react";
import Carosal from "../miniComponents/Carosal";
import styles from "./Home.module.css";
import Products from "../miniComponents/Products";
import Homeimage1 from "../../edimages/homeimage1.png";
import Card from "../miniComponents/Card";
import images from "../miniComponents/ExportImage";
import ReviewCard from "../miniComponents/ReviewCard";
import profile1 from "../../edimages/profile.png";
import profile2 from "../../edimages/profile1.png";
import GreatDealCard from "../miniComponents/GreatDealCard";
import leaf from "../../edimages/logo-leaf-new.png";
import VideoPlayer from "./VideoPlayer";
import video from '../../assests/labvideo.mp4';
import Lab from "./Lab";
import { labdata,factorydata } from "../../assests/LabData";
import lab1 from '../../assests/lab.jpg';
const productsData = [
  {
    id: 1,
    image: Homeimage1,
    tag: "Best Quality Products",
    heading: "Airavat Oils",
    description:
      "Airavat Brand is the number one mustard oil distributor to trust. Established in 2022, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:
      "+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 15 Ml\nPure & Healthy\n100 % Organic\nMustard Oil Tin",
    detailsDesc:
      "Welcome To AIRAVAT OILS\nIndia’s Pure & Healthy Mustard Oil Company\n\n\nPure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\n\nThis isn’t your average mustard oil. Cold-pressed using traditional kacchi ghani methods, this 15 Litre Tin of liquid gold is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",
  },
];

export const Home = () => {
  const [isMobile, setIsMobile] = useState(false);
  const marqueeRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change 768 to your desired mobile width threshold
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className={styles.carosalmargin}>
        <Carosal />
      </div>
      <div style={{ marginBottom: "30px" }}>
        {productsData.map((product) => (
          <Products
            key={product.id}
            id={product.id}
            image={product.image}
            tag={product.tag}
            heading={product.heading}
            description={product.description}
          />
        ))}
      </div>
      <div className={styles.customer}>
        <img src={leaf} alt="logo" />
      </div>
      <div className={styles.customer}>
       <VideoPlayer src={video}/>
      </div>
      <div className={styles.customer}>
        <h1>Trending Products</h1>
        <img src={leaf} alt="logo" />
      </div>
      <div className={styles.mareffect}>
        {/* Render marquee only if not on mobile */}
        {!isMobile && (
          <marquee ref={marqueeRef}>
            <div className={styles.Productcard}>
              {images.map((list, index) => (
                <Card key={index} thumbimage={list.image} tag={list.tag} id={list.id}/>
              ))}
            </div>
         </marquee>
        )}
        {/* Render cards directly on mobile */}
        {isMobile && (
          <div className={styles.Productcard}>
            {images.map((list, index) => (
              <Card key={index} thumbimage={list.image} tag={list.tag}  id={list.id}/>
            ))}
          </div>
        )}
      </div>

      <div className={styles.customer} >
        <h1>Our Laboratory</h1>
        <img src={leaf} alt="logo" />
        <img src={leaf} alt="logo" />
      </div>
     {/* <div style={{backgroundImage: `url(${lab1})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100%', height: '100%'}}>  
        </div> */}
        <div className={styles.labotry} >
          <img src={lab1} alt="lab" className={styles.labimg} />
        </div>
      <div>
      {labdata.map((src, index) => (
      <Lab key={index} src={src}/>
      ))}
      </div>
      
      <div className={styles.customer} >
        <h1>Our Factory</h1>
        <img src={leaf} alt="logo" />
        <img src={leaf} alt="logo" />
      </div>
      <div>
      {factorydata.map((src, index) => (
      <Lab key={index} src={src}/>
      ))}
      </div>
      <div className={styles.customer}>
        <h1>Customer Reviews</h1>
        <img src={leaf} alt="logo" />
      </div>
      <div className={styles.reviewGrid}>
        <ReviewCard
          profileimg={profile1}
          desc="100% pure best quality oil at reasonable price! No adulteration. Strongly recommended to anyone who doesn't want to compromise with health!!"
          name="Vikram Singh"
        />
        <GreatDealCard />
        <ReviewCard
          profileimg={profile2}
          desc="Best quality Mustard oil and other variety of oils. I buy mustard oil since 2014. No complain in the items sold. I recommend my friends to visit this firm for quality oils."
          name="Sandeep Sharma"
        />
      </div>
    </div>
  );
};


