import lab1 from '../assests/lab1.mp4';
import lab2 from '../assests/lab2.MP4';
import lab3 from '../assests/lab3.MP4';
import factory1 from '../assests/factory1.MP4';
import factory2 from '../assests/factory2.MP4';
import factory3 from '../assests/factory3.MP4';
import factory4 from '../assests/factory4.MP4';
import factory5 from '../assests/factory5.MP4';
import factory6 from '../assests/factory6.MP4';
import factory7 from '../assests/factory7.MP4';
import factory9 from '../assests/factory9.MP4';


export const labdata =[lab1,lab2,lab3]; 
export const factorydata =[factory1,factory2,factory3,factory4,factory5,factory6,factory7,factory9]; 