import React from 'react';
import CustomSlider from './CustomSlider'; // Path to your CustomSlider component
import styles from './CustomSlider.module.css';
const ProductGallery = ({heading,subimages,subheadingArray}) => {

      
  return (
  <CustomSlider images={subimages} />
  );
};

export default ProductGallery;
