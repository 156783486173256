import React from "react";
import styles from './VideoPlayer.module.css';
import ReactPlayer from 'react-player';
const Lab = ({src}) => {

    return (
        <div className={styles.videoLabContainer}>
                {/* <video  controls muted  className={styles.videolab}>
                    <source src={src} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
      <ReactPlayer
        url={src}
        controls={true}
        width="100%"
        height="100%"
      />
        </div >
    );
}

export default Lab;