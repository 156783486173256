
import React from "react";
import styles from "./ReviewCard.module.css"; // Import module CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import SlideShow from "../Pages/aboutus/SlideShow";

const ReviewCard = ({profileimg,desc,name}) => {
  const star= <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} />;
  return (
    <div className={styles.card}>
      <SlideShow/>
      <div className={styles.cardContent}>
        <p className={styles.cardContentstar}>
          {star}
          {star}
          {star}
          {star}
          {star}
        </p>
        <p className={styles.cardText}>
         {desc}
        </p>
        <div className={styles.profile}>
          <img
            src={profileimg}
            alt="profile logo"
            className={styles.profileimg}
          />
          <p>{name}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
