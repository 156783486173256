import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import image1 from '../../edimages/banner1.jpeg';
import image2 from '../../edimages/banner2.jpeg';
import image3 from '../../edimages/banner3.jpeg';
import styles from './Carosal.module.css';
const Carosal = () => {
  return (
    <div className={styles.containers}>
         <Carousel  style={{width:'90%',margin:'auto',boxShadow: '3px 3px 5px 2px rgba(0, 0, 0, 0.5)'}}>
      <Carousel.Item>
        <img
        //   className="d-block w-100"
          src={image3}
          alt="First slide"
         className={styles.imgstyle}
        />
        {/* <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
        //   className="d-block w-100"
          src={image2}
          alt="Second slide"
          className={styles.imgstyle}
        />
        {/* <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
        //   className="d-block w-100"
          src={image1}
          alt="Third slide"
          className={styles.imgstyle}
        />
        {/* <Carousel.Caption>
          <h5>Third slide label</h5>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption> */}
      </Carousel.Item>
    </Carousel>
    </div>
  )
}

export default Carosal