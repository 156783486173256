import Homeimage1 from '../../../edimages/homeimage1.png';
import Homeimage2 from '../../../edimages/homeimage2.png';
import Homeimage3 from '../../../edimages/homeimage3.png';
import Homeimage4 from '../../../edimages/homeimage4.png';
import Homeimage5 from '../../../edimages/homeimage5.png';
import Homeimage6 from '../../../edimages/homeimage6.png';
import Homeimage7 from '../../../edimages/homeimage7.png';
import Homeimage8 from '../../../edimages/homeimage8.png';

const productsData = [
  {
    id: 1,
    image: Homeimage2,
    tag:'Best Quality Products',
    heading:'1 Ltr Kacchi Ghani Mustard Oil',
    description: "Airavat Brand is the number one mustard oil distributor to trust. Established in 2022, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:"+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 1 ltr\nPure & Healthy\n100 % Organic\nMustard Oil Bottle",
    subimage:[Homeimage2,Homeimage2,Homeimage1,Homeimage2,Homeimage2,Homeimage2],
    detailsDesc:"India’s Pure & Healthy Mustard Oil Company Pure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\nThis isn’t your average mustard oil. Cold-pressed using traditional Kacchi Ghani methods, this 1 Litre  of liquid oil is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",
  },
  {
    id: 2,
    image: Homeimage3,
    tag:'Best Quality Products',
    heading:'1 Ltr Kacchi Ghani Mustard Oil',
    description: "Airavat Brand is the number one mustard oil distributor to trust. Established in 2023, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:"+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 1 ltr\nPure & Healthy\n100 % Organic\nMustard Oil Pouch",
    subimage:[Homeimage3,Homeimage3,Homeimage1,Homeimage3,Homeimage3,Homeimage3],
    detailsDesc:"India’s Pure & Healthy Mustard Oil Company Pure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\nThis isn’t your average mustard oil. Cold-pressed using traditional Kacchi Ghani methods, this 1 Litre  of liquid oil is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",

  },
  {
    id: 3,
    image: Homeimage4,
    tag:'Best Quality Products',
    heading:'15 Litre Bucket Kacchi Ghani Mustard Oil',
    description: "Airavat Brand is the number one mustard oil distributor to trust. Established in 2023, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:"+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 15 ltr\nPure & Healthy\n100 % Organic\nMustard Oil Bucket",
    subimage:[Homeimage4,Homeimage4,Homeimage1,Homeimage4,Homeimage4,Homeimage4],
    detailsDesc:"India’s Pure & Healthy Mustard Oil Company Pure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\nThis isn’t your average mustard oil. Cold-pressed using traditional Kacchi Ghani methods, this 15 Litre  of liquid oil is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",

  },
  {
    id: 4,
    image: Homeimage5,
    tag:'Best Quality Products',
    heading:'15 Kg Kacchi Ghani Mustard Oil Tin',
    description: "Airavat Brand is the number one mustard oil distributor to trust. Established in 2023, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:"+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 15 Kg Tin\nPure & Healthy\n100 % Organic\nMustard Oil Tin",
    subimage:[Homeimage5,Homeimage5,Homeimage1,Homeimage5,Homeimage5,Homeimage5],
    detailsDesc:"India’s Pure & Healthy Mustard Oil Company Pure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\nThis isn’t your average mustard oil. Cold-pressed using traditional Kacchi Ghani methods, this 15 Kg  of liquid oil is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",

  },
  {
    id: 5,
    image: Homeimage6,
    tag:'Best Quality Products',
    heading:'200 ML  Kacchi Ghani Mustard Oil',
    description: "Airavat Brand is the number one mustard oil distributor to trust. Established in 2023, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:"+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 200 Ml\nPure & Healthy\n100 % Organic\nMustard Oil Bottle",
    subimage:[Homeimage6,Homeimage6,Homeimage1,Homeimage6,Homeimage6,Homeimage6],
    detailsDesc:"India’s Pure & Healthy Mustard Oil Company Pure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\nThis isn’t your average mustard oil. Cold-pressed using traditional Kacchi Ghani methods, this 200 ml  of liquid oil is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",

  },
  {
    id: 6,
    image: Homeimage7,
    tag:'Best Quality Products',
    heading:'5 Litre Kacchi Ghani Mustard Oil',
    description: "Airavat Brand is the number one mustard oil distributor to trust. Established in 2023, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:"+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 5 ltr\nPure & Healthy\n100 % Organic\nMustard Oil Jar",
    subimage:[Homeimage7,Homeimage7,Homeimage1,Homeimage7,Homeimage7,Homeimage7],
    detailsDesc:"India’s Pure & Healthy Mustard Oil Company Pure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\nThis isn’t your average mustard oil. Cold-pressed using traditional Kacchi Ghani methods, this 5 Litre  of liquid oil is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",

  },
  {
    id: 7,
    image: Homeimage8,
    tag:'Best Quality Products',
    heading:'500 ML Kacchi Ghani Mustard Oil',
    description: "Airavat Brand is the number one mustard oil distributor to trust. Established in 2023, Airavat Brand produces only the highest quality, 100% pure and organic mustard oil. Our oil comes from the finest organic mustard seeds, grown sustainably without the use of pesticides or chemicals. The seeds are slowly cold-pressed to extract every drop of natural flavor and nutrition, resulting in an oil that is rich in antioxidants and healthy fats. So don’t wait any longer, check out our ‘About Us’ page today and get to know Airavat Brand",
    subheading:"+ Free Shipping\nPure Kacchi Ghani Mustard Oil\nWeight : 500 ML\nPure & Healthy\n100 % Organic\nMustard Oil Bottle",
    subimage:[Homeimage8,Homeimage8,Homeimage1,Homeimage8,Homeimage8,Homeimage8],
    detailsDesc:"India’s Pure & Healthy Mustard Oil Company Pure Kacchi Ghani mustard oil is extracted through the traditional cold press method, so it retains all the rich nutrients, flavor and aroma of the mustard seed. The purest, healthiest choice for Indian cooking, this oil adds just the right richness and authentic taste to your curries, tikkas and parathas with no artificial flavors. Great for daily use in your kitchen.\n\nThis isn’t your average mustard oil. Cold-pressed using traditional Kacchi Ghani methods, this 500 ML  of liquid oil is packed with heart-healthy fats and a robust, spicy aroma. Its rich amber hue hints at the intense, nutty flavor within—earthy yet bright, with a kick of heat and bitterness that lingers. A staple of Indian cuisine, mustard oil has been used for centuries not just as a cooking oil but also as an all-natural remedy. Massage it into your skin and joints to relieve aches and improve circulation or gargle with it to soothe a sore throat. However you use it, this pure, all-natural elixir nourishes your body and soul. Once you experience its distinctive taste and aroma, regular vegetable oil just won’t cut it. This is mustard oil as nature intended—unrefined, unfiltered and unequivocally irresistible.\n\nThanks & Regards",

  },

];

const faqPageData = [
  {
    question: 'What is Mustard Oil?',
    answer: 'Mustard Oil, or sarso ka tel, is an oil made from pressing mustard seeds. It is popular in Indian cuisine for its pungent and floral aroma.',
  },
  {
    question: 'Why is Mustard Oil good for your health?',
    answer: 'Mustard oil is an essential ingredient for cooking and has many benefits for your health, including reducing skin inflammation, curing cold and cough, improving hair growth, and adding healthy fats to your diet.',
  },
  {
    question: 'What are cold pressed oils?',
    answer: 'Cold-pressed oils are those that are extracted from seeds without the use of heat. This method is said to preserve the oils’ flavor and nutrients better than other methods of extraction.',
  },
  {
    question: 'What does "Kacchi Ghani" refer to in Mustard Oil?',
    answer: 'Kacchi Ghani is a process that produces a type of mustard oil. This oil is made by cold pressing mustard seeds. So, Kacchi Ghani mustard oil is another name for cold-pressed mustard oils.',
  },
  {
    question: 'How are cold pressed oils better than refined oil?',
    answer: 'Cold-pressed oils are beneficial because they contain a high amount of essential nutrients like fatty acids, antioxidants, and vitamins E, K, and C. These oils also preserve the powerful nutrients in their ingredients and keep them in their natural form. By using cold-pressed oils, you can get all of the benefits of these healthy ingredients without losing any of their potency.',
  },
];

const star= <i class="fa fa-star" aria-hidden="true" style={{color: 'gold'}}></i>;

export { faqPageData, productsData,star };
